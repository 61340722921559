import React from 'react'
import Content from '../Content'
import PropTypes from 'prop-types'

const AboutPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <div>
{/*
      <section className='hero'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-10 is-offset-1'>
                <div className='section'>

                  <h1 className='title'>
                    {title}
                  </h1>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
*/}

      <section className='section section--gradient'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>



              <div className='section'>
                <PageContent className='content' content={content} />
                <h2 className='subtitle'>
                  <a href="/contact">Звертайтеся і ми Вам допоможемо!</a>
                </h2>
              </div>

              <div className="row">

              <div className="col-md-6">
                <iframe className='cart' src="https://www.youtube.com/embed/8M-mh4LWsDc"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen width="100%" height="425" frameBorder="0"></iframe>
              </div>
              <div className="col-md-6">
                <iframe className='cart' src="https://www.youtube.com/embed/UddjevEfPvU"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen width="100%" height="425" frameBorder="0"></iframe>
              </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default AboutPageTemplate
